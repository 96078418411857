import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import { PrivateRoute } from 'ui/common';
import {
  Alarms,
  DeviceDetails,
  EstateDetails,
  Estates,
  GatewayDetails,
  Gateways,
  Login,
  NotFound,
  Signup,
  Users,
  Verification,
  Welcome,
} from 'ui/screens';

const IndexPage = ({ location }) => {
  return (
    <Router basepath='/'>
      <Login path='/login' />
      <Signup path='/signup' />
      <Verification path='/verification' />
      <PrivateRoute path='/' component={Welcome} />
      <PrivateRoute path='/users' component={Users} />
      <PrivateRoute path='/estates' component={Estates} />
      <PrivateRoute path='/estates/:id' component={EstateDetails} />
      <PrivateRoute path='/alarms' component={Alarms} />
      <PrivateRoute path='/gateways' component={Gateways} />
      <PrivateRoute path='/gateways/:taekiaId' component={GatewayDetails} />
      <PrivateRoute path='/devices/:taekiaId' component={DeviceDetails} />
      <NotFound default />
    </Router>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
};

IndexPage.defaultProps = {};

export default IndexPage;
